import { ReactNode, useEffect } from 'react';
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import CardSimple from './CardSimple';
import imageAbb from '../../img/ema_whap_removeb.png';
import imageCipp from '../../img/cippatrice_removed.png';
import imagePot from '../../img/seghetto_bgremoved.png';
import imageSicur from '../../img/knot.png';



export default function CardsGrid() {
  const servicesList = [
    {
      uppertitle: 'servizio',
      cardTitle: 'Abbattimenti',
      boldSub: '',
      outlineSub: '',
      simpleSub: '',
      serviceDescription: '<p>Eseguiamo l&rsquo;<strong>abbattimento</strong> di alberi anche <strong>in spazi estremamente ridotti</strong>, legando e controllando ogni pezzo della pianta che viene depezzata gradualmente per evitare danni ad edifici o manufatti.</p><p><br></p><p><br></p>',
      serviceTags: ['sicuri', 'veloci'],
      image: imageAbb
    },
    {
      uppertitle: 'servizio',
      cardTitle: 'Messa in Sicurezza ',
      boldSub: '',
      outlineSub: '',
      simpleSub: '',
      serviceDescription: '<p><strong>Rendiamo sicuri gli alberi</strong> che, soprattutto in prossimit&agrave; di zone abitate, presentano sintomi di cedimento strutturale.</p><p>Attuiamo un insieme di tecniche di <strong>consolidamento</strong> atte a rendere pi&ugrave; sicura la chioma e ad evitare la rottura delle parti deboli collocando tiranti statici o dinamici tra i rami o le parti del fusto.</p>',
      serviceTags: ['sicuri', 'veloci'],
      image: imageSicur
    },
    {
      uppertitle: 'servizio',
      cardTitle: 'Fresatura Ceppaie',
      boldSub: '',
      outlineSub: '',
      simpleSub: '',
      serviceDescription: '<p><strong>Asportiamo la ceppaia</strong> in profondit&agrave; nel terreno <strong>permettendo il ripristino del terreno</strong> dopo l&rsquo;abbattimento dell&rsquo;albero per un&rsquo;eventuale ripiantumazione o per la creazione di un tappeto erboso.</p><p><br></p>',
      serviceTags: ['sicuri', 'veloci'],
      image: imageCipp
    },
    {
      uppertitle: 'servizio',
      cardTitle: 'Potature',
      boldSub: '',
      outlineSub: '',
      simpleSub: '',
      serviceDescription: '<p>Interveniamo nella <strong>potatura </strong>con l&rsquo;obbiettivo di <strong>sostenere l&rsquo;albero nella costruzione di una chioma stabile</strong> <strong>aiutandolo ad adeguarsi agli spazi di crescita limitati</strong>, rimuovendo o contenendo (tramite accorciamento) le parti della chioma che potrebbero rivelarsi instabili, asportando i rami morti, quelli danneggiati. Eliminiamo ricacci e rami che crescono troppo vicini e non possono svilupparsi in modo adeguato garantendo cosi la penetrazione della luce e la circolazione dell&rsquo;aria che sono fattori indispensabili per lo <strong>sviluppo di una pianta sana</strong>.</p><p><br></p>',
      serviceTags: ['sicuri', 'veloci'],
      image: imagePot
    },
  ];
  const serviceCardRows: any[] = new Array<number>(servicesList.length > 4 ? Math.ceil(servicesList.length / 3) : 1);

  return (
    <Box pt={12}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          SERVIZI
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Guarda se faccio al caso tuo consultando i servizi che
          copro!
        </Text>
      </VStack>
      <VStack
        pt={10}>
        {(serviceCardRows || []).fill(0).map((_: any, rowIdx: number) => (
          <Stack
            key={rowIdx}
            direction={{ base: 'column', md: 'row' }}
            textAlign="center"
            justify="center"
            spacing={{ base: 4, lg: 10 }}>
            {((servicesList.length > 4 ? servicesList.slice(rowIdx * 2, (rowIdx + 1) * 2) : servicesList) || []).map((service, serviceIdx) => (
              <CardSimple height='260px'
                key={`${rowIdx}-${serviceIdx}`}
                uppertitle={service.uppertitle}
                cardTitle={service.cardTitle}
                boldSub={service.boldSub}
                outlineSub={service.outlineSub}
                simpleSub={service.simpleSub}
                serviceDescription={service.serviceDescription}
                serviceTags={service.serviceTags}
                image={service.image} />
            ))
            }
          </Stack>
        ))}
      </VStack>
    </Box>
  );
}

// additional 2 cards::
//<Stack
//          direction={{ base: 'column', md: 'row' }}
//          textAlign="center"
//          justify="center"
//          spacing={{ base: 4, lg: 10 }}
//          pb={1}>
//
//          <CardSimple height='260px' uppertitle='SESTO S/G' cardTitle='Abbattimenti' boldSub='Sospesi' outlineSub='pericolosi' />
//
//
//          <CardSimple uppertitle='SESTO S/G' cardTitle='Abbattimenti' boldSub='Sospesi' outlineSub='pericolosi' />
//
//        </Stack>