import {
    Box,
    Container,
    HStack,
    Heading,
    Image,
    SpaceProps,
    Tag,
    TagLabel,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import React from 'react';

interface serviceProps {
    photo: any;
    serviceTitle: string;
    serviceDescription: string;
    serviceTags: string[];
}



interface BlogAuthorProps {
    date: Date;
    name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
    return (
        <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
            <Image
                borderRadius="full"
                boxSize="40px"
                src="https://100k-faces.glitch.me/random-image"
                alt={`Avatar of ${props.name}`}
            />
            <Text fontWeight="medium">{props.name}</Text>
            <Text>—</Text>
            <Text>{props.date.toLocaleDateString()}</Text>
        </HStack>
    );
};

const ServiceDetails = ({ photo, serviceTitle, serviceDescription, serviceTags }: serviceProps) => {
    return (
        <Container maxW={'6xl'} px="12">

            <Box
                marginTop={{ base: '1', sm: '5' }}
                display="flex"
                flexDirection={{ base: 'column', sm: 'row' }}
                justifyContent="space-between">
                <Box
                    display="flex"
                    flex="1"
                    mr="0"
                    position="relative"
                    alignItems="center">
                    <Box
                        rounded={'lg'}
                        mt={-12}
                        pos={'relative'}
                        height={'260px'}
                        _after={{
                            transition: 'all .3s ease',
                            content: '""',
                            w: 'full',
                            h: 'full',
                            pos: 'absolute',
                            top: 5,
                            left: -85,
                            backgroundImage: `url(${photo})`,
                            filter: 'blur(20px)',
                            zIndex: -1,
                        }}
                        _groupHover={{
                            _after: {
                                filter: 'blur(25px)',
                            },
                        }}>
                        <Image
                            rounded={'lg'}
                            width={'18rem'}
                            objectFit={'cover'}
                            src={photo}
                            alt={serviceTitle}
                        />
                    </Box>

                    <Box zIndex="-1" width="90%" position="absolute" height="100%">
                        <Box
                            bgGradient={useColorModeValue(
                                'radial(brand.200 1px, transparent 1px)',
                                'radial(brand.900 1px, transparent 1px)'
                            )}
                            backgroundSize="20px 20px"
                            opacity="2"
                            height="100%"
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex="1"
                    flexDirection="column"
                    justifyContent="center"
                    marginTop={{ base: '3', sm: '0' }}>
                    <Heading marginTop="1">
                        <Text
                            as={'span'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: '30%',
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bgGradient: 'linear(to-r, brand.200, brand.900)',
                                zIndex: -1,
                            }}>
                            {serviceTitle}
                        </Text>
                    </Heading>
                    <Text
                        as="p"
                        marginTop="2"
                        color={useColorModeValue('gray.700', 'gray.200')}
                        fontSize="md">
                        <div dangerouslySetInnerHTML={{ __html: serviceDescription }} />
                    </Text>
                    <HStack mt={4} spacing={4}>
                        {(serviceTags || []).map((tag: string) => (
                            <Tag p={1} px={3} size='md' colorScheme='green' borderRadius='full'>
                                <TagLabel>{tag}</TagLabel>
                            </Tag>
                        ))
                        }
                    </HStack>
                </Box>
            </Box>

        </Container>
    );
};

export default ServiceDetails;