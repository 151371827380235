import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { useState } from 'react';
import ServiceDetails from './ServiceDetails';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { ArrowForwardIcon } from '@chakra-ui/icons';



export default function CardSimple({ uppertitle, cardTitle, boldSub, outlineSub, simpleSub, serviceDescription, serviceTags, image }: any) {
  const [isMessageModalOpen, setMessageModalOpen] = useState<boolean>(false)
  const serviceTitle = cardTitle
  const whatsappLink = `https://wa.me/393402883373?text=Ciao%20Emanuele%20ho%20visto%20il%20tuo%20sito%20web%20e%20vorrei%20proporti:`
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <>
      <Modal isCentered isOpen={isMessageModalOpen} onClose={() => setMessageModalOpen((prev) => !prev)} size='4xl'>
        <ModalOverlay
          bg='blackAlpha.300'
          backdropFilter='blur(10px) hue-rotate(90deg)'
        />
        <ModalContent>
          <ModalBody>

            <ServiceDetails photo={image} serviceTitle={serviceTitle} serviceDescription={serviceDescription} serviceTags={serviceTags} />
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button mt='3rem' variant={'outline'} borderColor="#00AF66" color={'black'}

                _groupHover={{
                  _after: {
                    filter: 'blur(25px)',
                  },
                }}
                onClick={() => setMessageModalOpen((prev) => !prev)}>
                CLOSE
              </Button>
              <Button mt='3rem' leftIcon={<AiOutlineWhatsApp size={'1.6rem'} />} bg="brand.900" color={'black'}
                _after={{
                  transition: 'all .3s ease',
                  content: '""',
                  w: 'full',
                  h: 'full',
                  pos: 'absolute',
                  top: 2,
                  left: 0,
                  backgroundColor: 'brand.900',
                  filter: 'blur(20px)',
                  zIndex: -1,
                }}
                _groupHover={{
                  _after: {
                    filter: 'blur(25px)',
                  },
                }}
                onClick={() => openInNewTab(whatsappLink)}>
                Whatsapp
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Center py={12}>
        <Box
          onClick={() => setMessageModalOpen((prev) => !prev)}
          role={'group'}
          py={6}
          maxW={'270px'}
          w={'full'}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow={'2xl'}
          rounded={'lg'}
          pos={'relative'}
          zIndex={1}>
          <Box
            rounded={'lg'}
            mt={-12}
            pos={'relative'}
            height={'260px'}
            _after={{
              transition: 'all .3s ease',
              content: '""',
              w: 'full',
              h: 'full',
              pos: 'absolute',
              top: 5,
              left: -85,
              backgroundImage: `url(${image})`,
              filter: 'blur(40px)',
              zIndex: -1,
            }}
            _groupHover={{
              _after: {
                filter: 'blur(25px)',
              },
            }}>
            <Image
              fetchpriority='high'
              rounded={'lg'}
              width={'18rem'}
              objectFit={'cover'}
              src={image}
              alt={cardTitle}
            />
          </Box>
          <Stack pt={10} align={'center'}>
            <Text color={'gray.500'} fontSize={'sm'} textTransform={'uppercase'}>
              {uppertitle}
            </Text>
            <Heading fontSize={'2xl'} fontFamily={'body'} fontWeight={500}>
              {cardTitle}
            </Heading>
            <Stack direction={'row'} align={'center'}>
              <Text fontWeight={800} fontSize={'xl'}>
                {boldSub}
              </Text>
              <Text textDecoration={'line-through'} color={'gray.600'}>
                {outlineSub}
              </Text>
              <Text color={'gray.600'} fontSize={'md'}>
                {simpleSub}
              </Text>
            </Stack>
            <Tag style={{ cursor: 'pointer' }} p={1} px={3} as={'b'} size='md' colorScheme='green' borderRadius='full'>
              <TagLabel >ESPANDI <ArrowForwardIcon mb={'4%'} /></TagLabel>
            </Tag>
          </Stack>
        </Box>
      </Center>
    </>
  );
}